<template>
  <SubNav class="subnav-position" :selected="'team'" :id="id" />
  <main>
    <div v-if="teamMembers" class="subnav-detail">
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateTeamMember', params: { id: id } }"
        >
          Create Team Member
        </router-link>
      </div>

      <div v-if="error">{{ error }}</div>
      <div v-for="teamMember in teamMembers" :key="teamMember.id">
        <router-link
          :to="{
            name: 'EditTeamMember',
            params: { id: id, teamMemberId: teamMember.id }
          }"
        >
          <div class="row single">
            <div class="col">
              <div class="founder">
                <img :src="teamMember.headShotURL" />
              </div>
            </div>
            <div class="col">
              <div class="subject">
                {{ teamMember.name }}
              </div>
              <div class="title">
                {{ teamMember.title }}
              </div>
            </div>
            <div class="col message">
              <p v-html="teamMember.biography"></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
  <Footer v-if="teamMembers" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import Footer from "../../../components/navigation/Footer.vue";
export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const { error, documents: teamMembers } = getCollection(
      "teamMembers",
      [["offeringId", "==", props.id]],
      ["order", "asc"]
    );

    return {
      teamMembers,
      error
    };
  }
};
</script>

<style>
</style>